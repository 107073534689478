$(document).ready(function() {


  $('.carousel[data-type="multi"] .item').each(function(){
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendto($(this));
   
    for (var i=0;i<2;i++) {
      next=next.next();
      if (!next.length) {
      	next = $(this).siblings(':first');
    	}

      next.children(':first-child').clone().appendto($(this));
    }
  });

});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function remove(name, its) {
      data = $("input[name='original']").val();
      data = data.split(',');
      var index = data.indexOf(name);
      if (index > -1) {
         data.splice(index, 1);
      }
      $("input[name='original']").val(data.join(','));
      $(its).parents('.pic').fadeOut();
}

var updateCart = _.debounce(function(its) {
  quantity = $(its).val();
  id = $(its).attr('id');
  $.ajaxSetup({
      headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
  });
  jQuery.ajax({
       type: 'PUT',
       method : "PUT",
       url: "https://chessworld.am/cart/update/"+id+"/",
       data: {
         'qty': quantity,
       },
       beforeSend: function() {
           $("#checkout").attr('disabled','disabled');
       },
       success: function(data) {
            $("#checkout").removeAttr('disabled');
            price=$(its).parents('tr').find('strong[name="price"]').html();
            itemTotal = price * quantity;
            $(its).parents('tr').find('strong[name="itemTotal"]').html(itemTotal);
            total = 0;
            $('strong[name="itemTotal"]').each(function(index) {
              total +=  parseInt($(this).html());
            });
            total += " AMD";
            $('strong.total').html(numberWithCommas(total));
       },
       error: function(msg) {
         alert(msg);
       }
  });
}, 500);
